import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "wieSiehtDerNotarTerminAus";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    In der ersten Woche nehmen wir Kontakt mit der Bank auf, um etwaige Lasten im Grundbuch zu löschen.
                    Hierfür muss die Salden- sowie die Löschungsbestätigung an den Errichter des Kaufvertrags gesendet
                    werden. Darüber hinaus wenden wir uns an die Bank, bei der du deinen Kredit erhältst. Diese muss die
                    Treuhandvereinbarung und die Pfandurkunde an den Vertragserrichter übermitteln. Hinzu kommt der
                    sogenannte Grundverkehr, im Zuge dessen grundverkehrsrechtliche Bestimmungen wie Bewilligung oder
                    Negativbestätigung vorgenommen werden. Im Falle der Bewilligung solltest du nach höchstens zwei
                    Wochen den Entwurf des Kaufvertrags in den Händen halten. Anschließend klären wir alle
                    diesbezüglichen Fragen und besprechen deine Änderungswünsche.
                </p>
                <p>
                    Innerhalb von einer bis höchstens vier Wochen erhältst du einen Termin zur Unterzeichnung des
                    Kaufvertrags. Damit der Unterfertigungstermin (so lautet der Fachbegriff) vereinbart werden kann,
                    müssen bei der Kanzlei, die den Vertrag erstellt, einerseits die Unterlagen der finanzierenden Bank
                    eingelangt sein, also Treuhandschreiben und Pfandurkunde. Andererseits muss die Bank der
                    Verkäufer:in eine Saldobestätigung erbringen. Die Wartezeit ist hier folglich im Wesentlichen von
                    der Bearbeitungszeit der involvierten Banken abhängig. Ist der Kaufvertrag unterschrieben, so werden
                    der Kaufpreis bzw. die Nebenkosten auf die jeweiligen Konten überwiesen. Innerhalb von zwei bis acht
                    Wochen wird der Grundbuchantrag gestellt, der üblicherweise in zwei bis vier Wochen bewilligt wird.
                    Daraufhin wird die Hypothek ins Grundbuch eingetragen.
                </p>
            </Article>

            {/*TODO: Linking to affordability Calculator, Linking to other articals*/}

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData page={key} heading={question} description={answer} datePublished="2022-09-12" dateModified="2022-09-12" />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
